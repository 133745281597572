<template>
  <div class="card flex align-items-center justify-content-center">
    <div class="exception-panel p-5 flex flex-column justify-content-center">
      <h4 class="font-bold">ACESSO RESTRITO</h4>
      <span class="line-height-3">Desculpe, você não possui as credenciais para acesso a este recurso.</span>

      <img src="layout/images/pages/access-denied.svg" alt="access-denied" class="my-5" height="140">

      <Button label="Voltar para o Dashboard" @click="goDashboard" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goDashboard(){
      this.$router.push({ path: '/' });
    }
  }
}
</script>

<style scoped>

</style>
